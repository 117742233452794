import { Container, Grid } from 'theme-ui';
import SectionHeader from '../components/section-header';
import FeatureCard from '../components/feature-card.js';
import Smart from '../assets/feature/smart.svg';
import Winner from '../assets/feature/winner.svg';
import Cloud from '../assets/feature/cloud.svg';
import Setting from '../assets/feature/setting.svg';
import Design from '../assets/feature/design.svg';
import Chat from '../assets/feature/chat.svg';

const data = [
  {
    id: 1,
    imgSrc: Smart,
    altText: 'Smart',
    title: 'Smart Features',
    text:
      'EdStudio was built with AI at its core to help make the experience as personable as possible.',
  },
  {
    id: 2,
    imgSrc: Winner,
    altText: 'Retention',
    title: 'Improved Retention',
    text:
      'Do to our revolutionary educational experience, learners find significant boosts in knowledge retention.',
  },
  {
    id: 3,
    imgSrc: Cloud,
    altText: 'Content',
    title: 'Unlimited Content',
    text:
      'With over 10,000 videos, flash cards, articles, readings and questions, EdStudio is a learner\'s dream.',
  },
  {
    id: 4,
    imgSrc: Setting,
    altText: 'One-Stop Shop',
    title: 'One-Stop Shop',
    text:
      'Due to our integration with OpenAI and Gemini, EdStudio provides a singular location for all your educational needs.',
  },
  {
    id: 5,
    imgSrc: Design,
    altText: 'Productivity',
    title: 'Boost Productivity',
    text:
      'By utilizing EdStudio\'s bite-sized content as refreshers, Learners cut study/prep time by 33%.',
  },
  {
    id: 6,
    imgSrc: Chat,
    altText: 'Support',
    title: 'Customer Support',
    text:
      'Our AI Customer Support agent ensures that all Tier 1 issues are handled at a moment\'s notice.',
  },
];

export default function Feature() {
  return (
    <section sx={{ variant: 'section.feature' }}>
      <Container>
        <SectionHeader
          slogan=""
          title="More than just another AI tool / tutor"
        />

        <Grid sx={styles.grid}>
          {data.map((item) => (
            <FeatureCard
              key={item.id}
              src={item.imgSrc}
              alt={item.title}
              title={item.title}
              text={item.text}
            />
          ))}
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  grid: {
    pt: [0, null, null, null, null, null, null, 3],
    gridGap: [
      '37px 0',
      null,
      '45px 30px',
      null,
      '50px 30px',
      null,
      null,
      '90px 70px',
    ],
    width: ['100%', '80%', '100%'],
    mx: 'auto',
    gridTemplateColumns: [
      'repeat(1,1fr)',
      null,
      'repeat(2,1fr)',
      null,
      'repeat(3,1fr)',
    ],
  },
};
