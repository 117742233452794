// eslint-disable-next-line
export default [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'feature',
    label: 'Features',
  },
  {
    path: 'testimonial',
    label: 'Testimonial',
  },
  {
    path: 'pricing',
    label: 'Pricing',
  },
];
