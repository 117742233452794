import { Container, Grid } from 'theme-ui';
import SectionHeader from '../components/section-header';
import FeatureCardColumn from '../components/feature-card-column.js';
import Vector from '../assets/key-feature/vector.svg';
import Editing from '../assets/key-feature/editing.svg';
import Speed from '../assets/key-feature/speed.svg';

const data = [
  {
    id: 1,
    imgSrc: Vector,
    altText: 'Feed',
    title: 'Personalized Knowledge Feed',
    text:
      'EdStudio\'s personalized feed selects educational content tailored to your learning style, goals and engagement',
  },
  {
    id: 2,
    imgSrc: Editing,
    altText: 'Guide',
    title: 'AI Educational Guide',
    text:
      'Our guide transforms all content into engaging conversation topics, ensuring you truly grasp the material.',
  },
  {
    id: 3,
    imgSrc: Speed,
    altText: 'Content',
    title: 'Verified Educational Content',
    text:
    'All content is sourced from a list of reputable sources with preference given to partnered organizations.',
  },
];

export default function KeyFeature() {
  return (
    <section sx={{ variant: 'section.keyFeature' }} id="feature">
      <Container>
        <SectionHeader
          slogan="Quality features"
          title="Your Personal Learning Studio"
        />
        

        <Grid sx={styles.grid}>
          {data.map((item) => (
            <FeatureCardColumn
              key={item.id}
              src={item.imgSrc}
              alt={item.title}
              title={item.title}
              text={item.text}
            />
          ))}
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  grid: {
    px: [0, null, null, '40px', null, '80px'],
    pt: [0, null, null, null, null, null, null, 3],
    gridGap: ['35px 0', null, '40px 0'],
    gridTemplateColumns: [
      'repeat(1,1fr)',
      null,
      'repeat(2,1fr)',
      null,
      'repeat(3,1fr)',
    ],
    width: ['100%', '80%', '100%'],
    mx: 'auto',
    '& > div': {
      px: [0, '15px', null, '25px', null, '30px', '40px', '60px'],
    },
  },
};
