import React from 'react';
import Layout from './components/layout';
import SEO from './components/seo';
import KeyFeature from './sections/key-feature';
import CoreFeature from './sections/core-feature';
import Feature from './sections/feature';
import Banner from './sections/banner';
import WorkFlow from './sections/workflow';
import Package from './sections/package';
import AppProvider from './contexts/app/app.provider';
import theme from './theme';
import 'react-multi-carousel/lib/styles.css';
import 'react-modal-video/css/modal-video.min.css';
import 'rc-drawer/assets/index.css';
import { ThemeUIProvider } from 'theme-ui';
import styled from 'styled-components';

const MarginBtmAdd = styled.div`
  margin-bottom: 5rem
`;

function App() {
  return (
    <ThemeUIProvider theme={theme}>
      <AppProvider>
        <Layout>
        <MarginBtmAdd />
        <SEO title="Startup Landing 004" />
        <Banner />
        <MarginBtmAdd />
          <KeyFeature />
          <MarginBtmAdd />
          <CoreFeature />
          <MarginBtmAdd />
          <Feature />
          <MarginBtmAdd />
          <WorkFlow />
          <MarginBtmAdd />
          <Package />
          <MarginBtmAdd />
        </Layout>
        </AppProvider>
    </ThemeUIProvider>
  );
}

export default App;
