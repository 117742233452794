import React, { createContext, useContext } from 'react';
import { useCreateContext } from '../create-context';
import { reducer, initialState } from './app.reducer';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useCreateContext(initialState, reducer);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppState = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppState must be used withinan AppProvider');
  }
  return context;
};

export default AppProvider;