import React from 'react';
import { HeadProvider, Meta, Title } from 'react-head';

export default function SEO({
  description = 'startup apps landing page',
  author = 'RedQ, Inc',
  meta,
  title = 'startup landing title',
}) {
  const metaData = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
  ].concat(meta);
  return (
    <HeadProvider>
      <Title>{title}</Title>
      {metaData.map(({ name, content }, i) => (
        <Meta key={i} name={name} content={content} />
      ))}
    </HeadProvider>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
};
